<template>
  <!-- border: 1px solid #dadbca -->
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card style="border: 1px solid #dadbca;background: #fff" flat>
      <v-card-title>
        {{ $t('basicInfo') }}
      </v-card-title>

      <v-divider class="my-3" />

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('fname')"
                required
                v-model="profileForm.first_name"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('lname')"
                required
                v-model="profileForm.last_name"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('email')"
                required
                v-model="profileForm.email"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('phone')"
                required
                v-model="profileForm.phone"
                outlined
                type="number"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                :items="countries"
                :label="$t('country')"
                v-model="profileForm.country_id"
                :loading="loading"
                outlined
                :item-text="[$root.lang + 'name']"
                item-value="id"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                :items="sexTypes"
                :label="$t('sex')"
                v-model="profileForm.sex"
                :item-text="[$root.lang + 'name']"
                item-value="enname"
                outlined
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          style="minWidth: 80px"
          height="45"
          @click="submit"
          :disabled="loading"
          >{{ $t('save') }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import ApiService from '@/services/api.service';
import { mapGetters } from 'vuex';
import { localStoragePrefix } from '@/config/constVars';

export default {
  data() {
    return {
      loading: false,
      profileForm: {},
      valid: false,
      textRules: [(v) => !!v || 'field is required'],
      sexTypes: [
        { enname: 'male', arname: 'ذكر' },
        { enname: 'female', arname: 'أنثى' },
      ],
      countries: [],
    };
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        const { data } = await ApiService.get('users/me');
        const countries = await ApiService.get('items/countries');
        this.profileForm = data.data;
        this.countries = countries.data.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          this.loading = true;

          // const payload = {
          //   first_name: this.profileForm.first_name,
          //   last_name: this.profileForm.last_name,
          //   bio: this.profileForm.bio,
          //   country: this.profileForm.country,
          //   city: this.profileForm.city,
          // };

          let newValues = {};
          Object.entries(this.profileForm).forEach(([key, value]) => {
            if (this.profileForm[key] !== this.userGetter.currentUser[key])
              newValues[key] = value;
          });

          console.log('newval', newValues);
          console.log('user', this.userGetter.currentUser);

          if (Object.keys(newValues).length) {
            const updatedUser = await ApiService.update(
              'users',
              this.profileForm.id,
              newValues
            );

            console.log('updatedUser', updatedUser.data.data);
            localStorage.setItem(
              localStoragePrefix + 'userInfo',
              JSON.stringify(updatedUser.data.data)
            );

            window.location.reload();
          }

          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters(['userGetter']),
  },
  mounted() {
    this.fetchData();
    // this.profileForm = this.userGetter.currentUser;
    // Object.entries(this.userGetter.currentUser).forEach(([key, value]) => {
    //   this.profileForm[key] = value;
    // });
  },
};
</script>

<style></style>
